@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loading {
  transition: all 200ms ease-in-out;
}

.loadingNone {
  width: 0%;
  transition: all 1ms;
}

.loadingSlowFill {
  animation: loadingSlowFill 5s ease-in-out;
  width: 97%;
}

.loadingComplete {
  width: 100% !important;
}


@keyframes loadingSlowFill {
  0% {
    width: 0%;
  }
  50% {
    width: 80%;
  }
  75% {
    width: 95%;
  }
  100% {
    width: 97%;
  }
}